body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-dark-bg text-white;
  }
}

@layer components {
  .btn-primary {
    @apply bg-neon-green text-black px-6 py-2 rounded-md font-semibold hover:opacity-90 transition-opacity;
  }
  .btn-secondary {
    @apply bg-card-bg text-white px-6 py-2 rounded-md font-semibold hover:bg-gray-800 transition-colors;
  }
}
